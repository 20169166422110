import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '183, 28, 28',
		'primary-dark': '127, 0, 0',
		'accent': '253, 204, 16',
		'accent-plus': '0, 0, 0',
	},
});
